import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Bilder from "../../Detalj/Bilder";

import {
  getMiljogebyr,
  getMiljogebyrBilder,
  putMiljogebyrerBilder,
  deleteMiljogebyrerBilder,
  getMiljogebyrerHistorikk,
} from "../../../../actions/miljogebyrerActions";
import { authorized } from "../../../../utils/authorizationUtils";
import { bypatruljen, juridisk } from "../../../../constants/roles";

class BildContainer extends React.Component {
  static propTypes = {
    miljogebyrId: PropTypes.string.isRequired,
    miljogebyr: PropTypes.shape({}),
    bilder: PropTypes.arrayOf(PropTypes.shape({})),
    thumbnailBilder: PropTypes.arrayOf(PropTypes.shape({})),
    lightboxBilder: PropTypes.arrayOf(PropTypes.shape({})),
    BilderButtonsContainer: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
    checkbox: PropTypes.bool,
  };

  state = {
    bilder: [],
    thumbnailBilder: [],
    lightboxBilder: [],
  };

  isAuthorizedToPostAndPutBilder = () =>
    authorized([bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]);

  isAuthorizedForToastUi = () =>
    authorized([
      bypatruljen.saksbehandler,
      bypatruljen.saksbehandleradmin,
      juridisk.saksbehandler,
    ]);

  isAuthorizedToDeleteImage = () =>
    authorized([
      bypatruljen.saksbehandler,
      bypatruljen.saksbehandleradmin,
      juridisk.saksbehandler,
    ]);

  async componentDidMount() {
    this.props
      .getMiljogebyrBilder(
        this.props.miljogebyrId,
        {
          s3BucketCategory: "miljogebyrer_thumbnail",
        },
        false
      )
      .then((thumbnailBilder) => {
        this.setState(() => ({
          thumbnailBilder: thumbnailBilder,
        }));
      })
      .catch(() => {
        // Før brukte vi ikke thumbnails utan kun orginalbilde. Så dette er et førsøk på en fallback.
        this.props
          .getMiljogebyrBilder(this.props.miljogebyrId)
          .then((bilder) => {
            this.setState(() => ({ bilder: bilder }));
          });
      });

    this.props
      .getMiljogebyrBilder(
        this.props.miljogebyrId,
        {
          s3BucketCategory: "miljogebyrer_lightbox",
        },
        false
      )
      .then((lightboxBilder) => {
        this.setState(() => ({
          lightboxBilder: lightboxBilder,
        }));
      });
  }

  async componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.bilder) !== JSON.stringify(this.props.bilder)
    ) {
      this.props
        .getMiljogebyrBilder(
          this.props.miljogebyrId,
          {
            s3BucketCategory: "miljogebyrer_thumbnail",
          },
          false
        )
        .then((thumbnailBilder) => {
          this.setState(() => ({
            thumbnailBilder: thumbnailBilder,
          }));
        })
        .catch(() => {
          // Før brukte vi ikke thumbnails utan kun orginalbilde
          this.props
            .getMiljogebyrBilder(this.props.miljogebyrId)
            .then((bilder) => {
              this.setState(() => ({ bilder: bilder }));
            });
        });

      this.props
        .getMiljogebyrBilder(
          this.props.miljogebyrId,
          {
            s3BucketCategory: "miljogebyrer_lightbox",
          },
          false
        )
        .then((lightboxBilder) => {
          this.setState(() => ({
            lightboxBilder: lightboxBilder,
          }));
        });
    }
  }

  handleDeleteImageClick = async (bildeId) => {
    const {
      miljogebyrId,
      deleteMiljogebyrerBilder,
      getMiljogebyrerHistorikk,
      getMiljogebyr,
    } = this.props;
    await deleteMiljogebyrerBilder(miljogebyrId, bildeId);
    getMiljogebyrerHistorikk(miljogebyrId);
    await getMiljogebyr(miljogebyrId);
  };

  handleCheckboxChange = (event) => {
    const { miljogebyrId } = this.props;
    const { thumbnailBilder } = this.state;

    const bildeId = event.target.id;
    const bildeGoodQuality = event.target.checked;

    if (thumbnailBilder.length > 0) {
      this.setState((state) => ({
        thumbnailBilder: state.thumbnailBilder.map((bild) =>
          bild.id === bildeId
            ? { ...bild, isGoodQuality: bildeGoodQuality }
            : bild
        ),
      }));
    } else {
      this.setState((state) => ({
        bilder: state.bilder.map((bild) =>
          bild.id === bildeId
            ? { ...bild, isGoodQuality: bildeGoodQuality }
            : bild
        ),
      }));
    }

    this.props
      .putMiljogebyrerBilder(
        this.props.miljogebyr.id,
        event.target.id,
        event.target.checked
      )
      .then(() => {
        getMiljogebyrerHistorikk(miljogebyrId);
      })
      .catch(() => {
        if (thumbnailBilder.length > 0) {
          this.setState((state) => ({
            thumbnailBilder: state.thumbnailBilder.map((bild) =>
              bild.id === bildeId
                ? { ...bild, isGoodQuality: bildeGoodQuality }
                : bild
            ),
          }));
        } else {
          this.setState((state) => ({
            bilder: state.bilder.map((bild) =>
              bild.id === bildeId
                ? { ...bild, isGoodQuality: bildeGoodQuality }
                : bild
            ),
          }));
        }
      });
  };

  isLoadingImages = () =>
    (this.state.thumbnailBilder &&
      this.state.thumbnailBilder.length > 0 &&
      this.state.lightboxBilder &&
      this.props.miljogebyr &&
      this.props.miljogebyr.bilder &&
      this.state.thumbnailBilder.length !==
        this.props.miljogebyr.bilder.length &&
      this.state.lightboxBilder.length !==
        this.props.miljogebyr.bilder.length) ||
    (this.state.bilder &&
      this.state.bilder.length > 0 &&
      this.props.miljogebyr &&
      this.state.bilder.length !== this.props.miljogebyr.bilder.length);

  render() {
    const { thumbnailBilder, lightboxBilder, bilder } = this.state;

    const imageArray = thumbnailBilder.length > 0 ? thumbnailBilder : bilder;
    const lightboxImages = lightboxBilder.length > 0 ? lightboxBilder : bilder;

    if (this.props.checkbox && this.isAuthorizedToPostAndPutBilder()) {
      return (
        <section className="detalj-miljogebyr-section-margin">
          <Bilder
            id={this.props.miljogebyrId}
            bilderButtonsContainer={
              this.isAuthorizedToPostAndPutBilder()
                ? this.props.BilderButtonsContainer
                : null
            }
            imageArray={imageArray}
            lightboxImages={lightboxImages}
            isLoadingImages={this.isLoadingImages()}
            handleDeleteImageClick={this.handleDeleteImageClick}
            handleCheckboxChange={this.handleCheckboxChange}
            checkboxText="Til ettersendelse"
            isAuthorizedForToastUi={this.isAuthorizedForToastUi()}
            isAuthorizedToDeleteImage={this.isAuthorizedToDeleteImage()}
          />
        </section>
      );
    } else {
      return (
        <section className="detalj-miljogebyr-section-margin">
          <Bilder
            id={this.props.miljogebyrId}
            bilderButtonsContainer={
              this.isAuthorizedToPostAndPutBilder()
                ? this.props.BilderButtonsContainer
                : null
            }
            isLoadingImages={this.isLoadingImages()}
            handleDeleteImageClick={this.handleDeleteImageClick}
            imageArray={imageArray}
            lightboxImages={lightboxImages}
            isAuthorizedForToastUi={this.isAuthorizedForToastUi()}
          />
        </section>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    miljogebyr: state.miljogebyr,
    bilder:
      state.miljogebyr &&
      state.miljogebyr.bilder &&
      state.miljogebyr.bilder.map((bild) => {
        return { id: bild.id, isGoodQuality: bild.isGoodQuality };
      }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMiljogebyr,
      getMiljogebyrBilder,
      putMiljogebyrerBilder,
      deleteMiljogebyrerBilder,
      getMiljogebyrerHistorikk,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BildContainer);
