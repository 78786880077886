import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Modal from "../../Common/ModalGeneric";
import map from "lodash/map";
import ModalGeneric from "../../Common/ModalGeneric";
import OpenModalComponent from "../CreateModals/OpenModalComponent";
import BybetjentCreateModalContent from "../BybetjentForm";
import {
  getBybetjenter,
  postBybetjenter,
  addBetjentToTeam,
  getBybetjentById,
} from "../../../actions/bybetjenterActions";
import { getBybetjentGrupper } from "../../../actions/bybetjentgrupperAction";
import { getBybetjentRoller } from "../../../actions/bybetjentrollerAction";
import { getBybetjentSeksjoner } from "../../../actions/bybetjentseksjonerAction";
import {
  IsValidated,
  onChangeValidation,
} from "../../../utils/validationUtils";

class Bybetjent extends React.Component {
  static propTypes = {
    grupper: PropTypes.array,
    seksjoner: PropTypes.array,
    roller: PropTypes.array,
    getBybetjentGrupper: PropTypes.func,
    getBybetjentRoller: PropTypes.func,
    getBybetjentSeksjoner: PropTypes.func,
    postBybetjenter: PropTypes.func,
    addBetjentToTeam: PropTypes.func,
    getBybetjentById: PropTypes.func,
  };

  get initState() {
    return {
      open: false,
      submitButtonText: "Legg til ny",
      nummer: "",
      team: [],
      seksjon: 0,
      rolle: 0,
      navn: "",
      mobil: "",
      epost: "",
      aktivertDato: "",
      deaktivertDato: "",
      validationError: {},

      openDeaktiverConfirm: false,
      deaktiveringConfirmed: false,
    };
  }

  state = this.initState;
  rules = {
    navn: "required;minLength=3",
    nummer: "required;isDigit",
    mobil: "isDigit;length=8",
    aktivertDato: "isDato;isFuture;isLessThan=deaktivertDato",
    deaktivertDato: "isDato;isFuture;isLargerThan=aktivertDato",
    epost: "isEmail",
    rolle: "required",
    seksjon: "required",
  };

  componentDidMount() {
    this.props.getBybetjentGrupper();
    this.props.getBybetjentRoller();
    this.props.getBybetjentSeksjoner();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { deaktiveringConfirmed } = this.state;
    if (
      deaktiveringConfirmed === true &&
      prevState.deaktiveringConfirmed !== deaktiveringConfirmed
    ) {
      this.saveAndClose();
    }
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  generateTeamPayload = (state) => {
    const team = state.team;
    const teamIds = team.map((i) => i.id);
    return Object.assign(
      {},
      {
        nummer: state.nummer,
        navn: state.navn,
        team: teamIds,
        seksjon: state.seksjon,
        rolle: state.rolle,
        mobil: state.mobil,
        epost: state.epost,
        aktivertDato: state.aktivertDato
          ? state.aktivertDato.toISOString()
          : null,
        deaktivertDato: state.deaktivertDato
          ? state.deaktivertDato.toISOString()
          : null,
      }
    );
  };

  saveAndClose = async () => {
    const { deaktiveringConfirmed, deaktivertDato } = this.state;

    if (this.isValid() && (deaktiveringConfirmed || !deaktivertDato)) {
      const payload = this.generateTeamPayload(this.state);
      const betjent = await this.props.postBybetjenter(payload);
      await Promise.all(
        map(payload.team, async (item) => {
          await this.props.addBetjentToTeam(betjent.id, item);
        })
      );
      await this.props.getBybetjenter();
      this.closeModal();
    } else if (this.isValid() && deaktivertDato) {
      this.setState({ open: false, openDeaktiverConfirm: true });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.parseNumberAndSetState(name, value);
    onChangeValidation(e, this.state, this.rules);
  };

  //SAK-80: parsing numbers and not defined dates is set as null
  parseNumberAndSetState = (name, value) => {
    if (name === "nummer") {
      this.setState({ [name]: parseInt(value) });
    } else {
      this.setState({ [name]: value });
    }
  };

  betjentroller = () => {
    return map(this.props.roller, (i) =>
      Object.assign([], { id: i.kode, navn: i.beskrivelse })
    );
  };

  render() {
    const { grupper, seksjoner } = this.props;
    const { openDeaktiverConfirm } = this.state;
    const roller = this.betjentroller();
    return (
      <React.Fragment>
        <Modal
          // className="bybetjenterModal"
          modalTitle="Legg til ny bypatrulje ansatt"
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={OpenModalComponent}
          submit={this.saveAndClose}
          submitButtonText={this.state.submitButtonText}
        >
          <BybetjentCreateModalContent
            teamList={grupper}
            seksjoner={seksjoner}
            roller={roller}
            rolle={this.state.rolle}
            seksjon={this.state.seksjon}
            nummer={this.state.nummer}
            team={this.state.team}
            navn={this.state.navn}
            mobil={this.state.mobil}
            epost={this.state.epost}
            aktivertDato={this.state.aktivertDato}
            deaktivertDato={this.state.deaktivertDato}
            validationError={this.state.validationError}
            handleChange={this.handleChange}
            // handleChangeSelect={this.handleChangeSelect}
          />
        </Modal>
        <ModalGeneric
          modalTitle="Deaktiver bruker"
          open={openDeaktiverConfirm}
          closeModal={() =>
            this.setState({ openDeaktiverConfirm: false, open: true })
          }
          submit={() => this.setState({ deaktiveringConfirmed: true })}
          submitButtonText={this.state.submitButtonText}
        >
          <p>Vil du deaktivere betjent {this.state.navn}?</p>
        </ModalGeneric>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    grupper: state.betjentgrupper,
    roller: state.betjentroller,
    seksjoner: state.bybetjenterseksjoner,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      getBybetjenter,
      getBybetjentGrupper,
      getBybetjentRoller,
      getBybetjentSeksjoner,
      postBybetjenter,
      addBetjentToTeam,
      getBybetjentById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Bybetjent);
