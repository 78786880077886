import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import ModalGenericPostCancelContainer from "../../../Common/ModalGenericPostCancelContainer";
import TextareaModalContent from "../../../Common/IleggelseClient/TextareaModalContent";

import { getIleggelse as getIleggelseAction } from "../../../../actions/ileggelserActions";
import { getIleggelseEttersendelser as getIleggelseEttersendelserAction } from "../../../../actions/ileggelseEttersendelserActions";
import { postIleggelseEttersendelser as postIleggelseEttersendelserAction } from "../../../../actions/ileggelseEttersendelserActions";

import { commentHasToBeBetween } from "../../../../constants/ErrorMessages";
import {
  ettersendelsesrapportMinLength,
  ettersendelsesrapportMaxLength,
} from "../../../../constants/globals";

class NyEttersendelseContainer extends React.Component {
  static propTypes = {
    ileggelse: PropTypes.object,
    getIleggelse: PropTypes.func.isRequired,
    getIleggelseEttersendelser: PropTypes.func.isRequired,
    postIleggelseEttersendelser: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      kommentar: null,
      kommentarValidationError: null,
    };

    this.handleApiCall = this.handleApiCall.bind(this);
  }

  validation = () => {
    const { kommentar } = this.state;
    return kommentar &&
      kommentar.length >= ettersendelsesrapportMinLength &&
      kommentar.length <= ettersendelsesrapportMaxLength
      ? true
      : false;
  };
  
  async handleApiCall(ileggelseId) {
    if (this.validation()) {
      return this.props
        .postIleggelseEttersendelser(ileggelseId, {
          kommentar: this.state.kommentar,
        })
        .then((response) => {
          this.setState({ kommentar: null, kommentarValidationError: null });
          this.props.getIleggelse(ileggelseId);
          this.props.getIleggelseEttersendelser(ileggelseId);
          this.props.reloadHistorikk();
          return Promise.resolve();
        })
        .catch((error) => Promise.reject(error));
    } else {
      this.setState({ kommentarValidationError: true });
      return Promise.reject("validationError");
    }
  }

  handleKommentarChange = (event) => {
    this.setState({ kommentar: event.target.value });
  };

  render() {
    if (
      !this.props.ileggelse.isTilEttersendelse &&
      !this.props.ileggelse.isEttersendelseTilGodkjenning &&
      this.props.ileggelse.forsinketMakuleringStatus &&
      !this.props.ileggelse.forsinketMakuleringStatus.deletePossible &&
      !this.props.ileggelse.isMakulert &&
      !this.props.ileggelse.isBetalt &&
      this.props.ileggelse.leveringstype.id === 3
    ) {
      return (
        <ModalGenericPostCancelContainer
          id={this.props.ileggelse.id}
          modalTitle={`Ny ettersendelse av ileggelse ${this.props.ileggelse.ileggelsesnummer}`}
          submitButtonText="Lag ny ettersendelse"
          submittingButtonText="Lager ny ettersendelse..."
          successPostCallFlashMessage="Endring fullført"
          errorPostCallFlashMessage="Endring feilet"
          handleApiCall={this.handleApiCall}
          openModalComponentFunction={(props) => (
            <Button
              className="btn btn-bym-standard"
              style={{ marginLeft: 20 }}
              onClick={props.openModal}
            >
              Ny Ettersendelse
            </Button>
          )}
        >
          <TextareaModalContent
            paragrapghText={`Ønsker du å lage en ny ettersendelse for ileggele ${this.props.ileggelse.ileggelsesnummer}?`}
            kommentarValidationError={this.state.kommentarValidationError}
            textareaLabel="Ettersendelsesrapport"
            textareaPlaceholder="Ettersendelsesrapport"
            validationErrorText={commentHasToBeBetween(
              ettersendelsesrapportMinLength,
              ettersendelsesrapportMaxLength
            )}
            handleChange={this.handleKommentarChange}
          />
        </ModalGenericPostCancelContainer>
      );
    } else {
      return <span />;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ileggelse: state.ileggelse,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getIleggelse: (ileggelseId) => dispatch(getIleggelseAction(ileggelseId)),
    getIleggelseEttersendelser: (ileggelseId) =>
      dispatch(getIleggelseEttersendelserAction(ileggelseId)),
    postIleggelseEttersendelser: (ileggelseId, params) =>
      dispatch(postIleggelseEttersendelserAction(ileggelseId, params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NyEttersendelseContainer);
