import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import TextInput from "../Common/Forms/TextInput";
import DropDown from "../Common/Forms/DropDown";
import DropDownListWithCheckbox from "../Common/Forms/DropDownListMultiCheckboxOptions";
import Datepicker from "../Common/Forms/Datepicker";

export default class Bybetjent extends React.Component {
  static propTypes = {
    nummer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    teamList: PropTypes.array,
    team: PropTypes.array,
    roller: PropTypes.array,
    rolle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    seksjoner: PropTypes.array,
    seksjon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    navn: PropTypes.string,
    mobil: PropTypes.string,
    epost: PropTypes.string,
    aktivertDato: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    deaktivertDato: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    validationError: PropTypes.object,
    handleChange: PropTypes.func,
    handleChangeSelect: PropTypes.func,
    actionType: PropTypes.string,
    ID: PropTypes.string,
  };

  constructor(props) {
    super(props);
    let nummer = props.nummer;
    this.state = {
      initNummer: nummer,
    };
  }

  render() {
    const {
      validationError,
      teamList,
      team,
      roller,
      rolle,
      seksjoner,
      seksjon,
      navn,
      epost,
      nummer,
      deaktivertDato,
      aktivertDato,
      mobil,
      handleChange,
    } = this.props;
    return (
      <form>
        <Row>
          <Col md={6}>
            <TextInput
              label="Tjenestenummer"
              onChange={handleChange}
              value={nummer}
              name="nummer"
              validationError={validationError.nummer}
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput
              label="Navn"
              onChange={handleChange}
              value={navn}
              name="navn"
              validationError={validationError.navn}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label="Mobilnummer"
              onChange={handleChange}
              value={mobil}
              name="mobil"
              validationError={validationError.mobil}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextInput
              label="E-post"
              onChange={handleChange}
              type="email"
              value={epost}
              name="epost"
              validationError={validationError.epost}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Datepicker
              label="Aktivert dato"
              id="aktivertDato"
              name="aktivertDato"
              onChange={handleChange}
              locale="nb"
              timeFormat={false}
              value={aktivertDato}
              placeHolder="Velg"
              validationError={validationError.aktivertDato}
            />
          </Col>
          <Col md={6}>
            <Datepicker
              label="DeaktivertDato"
              id="deaktivertDato"
              name="deaktivertDato"
              onChange={handleChange}
              locale="nb"
              timeFormat={false}
              value={deaktivertDato}
              placeHolder="Velg"
              validationError={validationError.deaktivertDato}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DropDownListWithCheckbox
              label="Team"
              name="team"
              items={teamList}
              selected={team}
              title={team && team.length > 0 ? `${team.length} valg` : "Velg"}
              onChange={handleChange}
              validationError={validationError.team}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DropDown
              label="Rolle"
              name="rolle"
              items={roller}
              selected={rolle}
              onChange={handleChange}
              validationError={validationError.rolle}
              title="Velg"
            />
          </Col>
          <Col md={6}>
            <DropDown
              label="Seksjon"
              name="seksjon"
              items={seksjoner}
              onChange={handleChange}
              selected={seksjon}
              title="Velg"
              validationError={validationError.seksjon}
            />
          </Col>
        </Row>
      </form>
    );
  }
}
