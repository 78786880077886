import axiosPackage from "axios";
import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";
import { errorData as axiosUtilsErrorData } from "../utils/axiosUtils";
import { setTotal as setTotalAction } from "./advancedListActions";

import { addFlashMessage } from "./FlashMessagesAction";

import { toIsoString } from "../utils/datetimeUtils";
import { errorData } from "../utils/apiResponseUtils";
import { getBase64ObjectURL } from "../utils/imageUtils";
import { popUpBlocked } from "../constants/ErrorMessages";

export const GET_ILEGGELSER_SUCCESS = "GET_ILEGGELSER_SUCCESS";
export const GET_ILEGGELSER_FAILURE = "GET_ILEGGELSER_FAILURE";

export function getIleggelser(params, page, count, cancelToken = null) {
  return async (dispatch, getState) => {
    // Temporary while fetching is so increadably slow...
    dispatch({
      type: GET_ILEGGELSER_SUCCESS,
      payload: [],
    });
    return axios
      .get(api.GET_ILEGGELSER_URL, {
        cancelToken,
        params: {
          ...params,
          fraDato: params.fraDato && toIsoString(params.fraDato),
          tilDato: params.tilDato && toIsoString(params.tilDato),
          fraIneOverforingsdato:
            params.fraIneOverforingsdato &&
            toIsoString(params.fraIneOverforingsdato),
          tilIneOverforingsdato:
            params.tilIneOverforingsdato &&
            toIsoString(params.tilIneOverforingsdato),
          page: page,
          pageSize: count,
        },
      })
      .then((response) => {
        const total = JSON.parse(response.headers["x-pagination"]).Total;
        dispatch(setTotalAction("ileggelser", total));
        dispatch({
          type: GET_ILEGGELSER_SUCCESS,
          payload: response.data.result,
        });
        return Promise.resolve(true);
      })
      .catch((error) => {
        if (axiosPackage.isCancel(error)) {
          return false;
        }
        const errorMessage = axiosUtilsErrorData(error);
        dispatch(setTotalAction("ileggelser", 0));
        dispatch(
          addFlashMessage({
            type: "error",
            message: errorMessage,
          })
        );
        dispatch({
          type: GET_ILEGGELSER_FAILURE,
        });
        return Promise.reject(error);
      });
  };
}

export const GET_ILEGGELSE_SUCCESS = "GET_ILEGGELSE_SUCCESS";
export const GET_ILEGGELSE_FAILURE = "GET_ILEGGELSE_FAILURE";

export function getIleggelse(ileggelseId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_ILEGGELSE_URL(ileggelseId))
      .then(handle.success(dispatch, GET_ILEGGELSE_SUCCESS))
      .catch(handle.error(dispatch, GET_ILEGGELSE_FAILURE));
  };
}

export function putIleggelser(ileggelseId, params) {
  return async () => {
    return axios
      .put(api.PUT_ILEGGELSE_URL(ileggelseId), params)
      .then((response) => ({ resolved: true, data: response }))
      .catch((error) => ({ resolved: false, data: error }));
  };
}

export function getIleggelseByNummer(ileggelsesnummer) {
  return (dispatch) => {
    return axios
      .get(api.GET_ILEGGELSER_URL, {
        params: { ileggelsesnummer: ileggelsesnummer },
      })
      .then(handle.success(dispatch, GET_ILEGGELSE_SUCCESS, null, {}, true))
      .catch(handle.error(dispatch, GET_ILEGGELSE_FAILURE));
  };
}

export function postIleggelserBilder(
  ileggelseId,
  file,
  showSuccessFlashMessage = false,
  showErrorFlashMessage = false
) {
  return async (dispatch) => {
    let formData = new FormData();
    formData.append("Bilde", file);
    return axios
      .post(api.POST_ILEGGELSER_BILDER_URL(ileggelseId), formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        if (showSuccessFlashMessage) {
          dispatch(
            addFlashMessage({ type: "success", text: "Lagt til bilde" })
          );
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (showErrorFlashMessage) {
          const errorMessage = errorData(error);
          dispatch(
            addFlashMessage({
              type: "error",
              message: errorMessage,
            })
          );
        }
        return Promise.reject(error);
      });
  };
}

export function getIleggelseBilde(url) {
  return async () => {
    return axios.get(url + "/base64").then((response) => {
      const result = response.data.result;
      const base64 = "data:image/png;base64," + result.base64;
      return { src: base64, navn: result.filename };
    });
  };
}

export const getIleggelseBildeBase64 = (ileggelseId, bildeId) => {
  return async (dispatch) => {
    return axios
      .get(api.GET_ILEGGELSE_BILDE_BASE64_URL(ileggelseId, bildeId))
      .then(async (response) => {
        const result = response.data.result;

        const src = await getBase64ObjectURL(
          `data:image/png;base64,${result.base64}`
        );

        return Promise.resolve({ navn: result.filename, src });
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            message: errorMessage,
          })
        );

        return Promise.reject(false);
      });
  };
};

export const GET_ILEGGELSE_BILDER_SUCCESS = "GET_ILEGGELSE_BILDER_SUCCESS";

export function getIleggelseBilder(
  ileggelseId,
  cancelToken,
  params = {},
  displayFlashMessage = true
) {
  return async (dispatch) => {
    const url = api.GET_ILEGGELSER_BILDER_BASE_URL(ileggelseId);
    return axios
      .get(url + "/base64", { cancelToken, params })
      .then(async (response) => {
        const result = response.data.result;

        const bilder = await Promise.all(
          result.map(async (item) => ({
            ...item,
            src: await getBase64ObjectURL(item.src),
          }))
        );

        dispatch({
          type: GET_ILEGGELSE_BILDER_SUCCESS,
          payload: {
            ileggelseId,
            bilder: bilder.reduce(
              (acc, bilde) => ({
                ...acc,
                [bilde.id]: bilde,
              }),
              {}
            ),
          },
        });

        return bilder;
      })
      .catch((error) => {
        if (axiosPackage.isCancel(error)) {
          return Promise.reject();
        }

        const errorMessage = errorData(error);

        if (displayFlashMessage) {
          dispatch(
            addFlashMessage({
              type: "error",
              message: errorMessage,
            })
          );
        }

        return Promise.reject(error);
      });
  };
}

export function putIleggelserBilder(ileggelseId, bildeId, isGoodQuality) {
  return async (dispatch) => {
    return axios
      .put(api.PUT_ILEGGELSER_BILDER_URL(ileggelseId, bildeId), {
        isGoodQuality,
      })
      .then(() => {
        dispatch(addFlashMessage({ type: "success", text: "Oppdatert" }));
        return true;
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            message: errorMessage,
          })
        );
        return false;
      });
  };
}

export function deleteIleggelserBilder(ileggelseId, bildeId) {
  return async (dispatch) => {
    return axios
      .delete(api.DELETE_ILEGGELSER_BILDER_URL(ileggelseId, bildeId))
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Fjernet" }));
        return response;
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            message: errorMessage,
          })
        );
        return errorMessage;
      });
  };
}

export const GET_ILEGGELSE_HISTORIKK_SUCCESS =
  "GET_ILEGGELSE_HISTORIKK_SUCCESS";
export const GET_ILEGGELSE_HISTORIKK_FAILURE =
  "GET_ILEGGELSE_HISTORIKK_FAILURE";

export function getIleggelseHistorikk(ileggelseId) {
  return (dispatch, getState) => {
    return axios
      .get(api.GET_ILEGGELSE_HISTORIKK(ileggelseId))
      .then(handle.success(dispatch, GET_ILEGGELSE_HISTORIKK_SUCCESS))
      .catch((error) => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: error,
          })
        );
        return Promise.reject(true);
      });
  };
}

export function getIleggelseKopi(ileggelseId, ileggelsesnummer) {
  return async (dispatch) => {
    return axios
      .get(api.GET_ILEGGELSE_KOPI_URL(ileggelseId))
      .then((response) => {
        let tabName = `${ileggelsesnummer} - Ileggelse.pdf`;
        var byteCharacters = window.atob(response.data.result.base64_kopi);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new File([byteArray], `${ileggelsesnummer} - Ileggelse`, {
          type: "application/pdf",
        });
        window.URL = window.URL || window.webkitURL;
        var blobUrl = window.URL.createObjectURL(blob);
        let pdfWindow = window.open("");
        if (
          !pdfWindow ||
          pdfWindow.closed ||
          typeof pdfWindow.closed === "undefined"
        ) {
          throw new Error(popUpBlocked);
        } else {
          pdfWindow.location.href = blobUrl;
          pdfWindow.document.title = tabName;
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        let allowDangerouslySetInnerHtml = false;
        let errorMessage = "";
        if (!error.response && error.message) {
          allowDangerouslySetInnerHtml = true;
          errorMessage = error.message;
        } else {
          errorMessage = errorData(error);
        }
        dispatch(
          addFlashMessage({
            type: "error",
            text: errorMessage,
            allowDangerouslySetInnerHtml,
          })
        );
        return Promise.reject(false);
      });
  };
}

export const GET_ILEGGELSER_ILEGGELSEKLAGER_SUCCESS =
  "GET_ILEGGELSER_ILEGGELSEKLAGER_SUCCESS";

export function getIleggelseKlager(ileggelseId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_ILEGGELSER_ILEGGELSEKLAGER(ileggelseId))
      .then(handle.success(dispatch, GET_ILEGGELSER_ILEGGELSEKLAGER_SUCCESS))
      .catch((error) => {
        throw error;
      });
  };
}

export function putIleggelserSettKlarTilIne(ileggelseId) {
  return async (dispatch) => {
    return axios
      .put(api.PUT_ILEGGELSER_SETT_KLAR_TIL_INE_URL(ileggelseId), {
        settKlarTilKemner: true,
      })
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Oppdatert" }));
        Promise.resolve(response);
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            text: errorMessage,
          })
        );
        return Promise.reject(error);
      });
  };
}
